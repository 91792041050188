
































































































































































import Vue from "vue"
import TextHighlight from "vue-text-highlight"

import { Client } from "@/types/client"

import {
  MODULE_NAME as AUTH_MODULE_NAME,
  GetterTypes as AuthGetterTypes
} from "@/store/AuthModule"

import {
  RtbButton,
  RtbLink,
  RtbCard,
  RtbSelect,
  RtbCardBody,
  RtbSpinner
} from "@/components/ui"

import SearchField from "@/components/SearchField.vue"

import ClientEdit, { Action } from "./ClientEdit.vue"

import SessionType from "@shared/enums/Session"

import Session from "@shared/Session"

import { db } from "@/firebase"

enum Filter {
  Expo,
  Template,
  ContentToolTemplate,
  Custom,
  Standard,
  OpenWeve
}

const FILTER_TO_PREDICATE = {
  [Filter.Expo]: (client: Client) => client.tournament,
  [Filter.Standard]: (client: Client) => !client.tournament,
  [Filter.Template]: (client: Client) =>
    client.category === SessionType.TEMPLATE,
  [Filter.ContentToolTemplate]: (client: Client) =>
    client.category === SessionType.CONTENT_TOOL_TEMPLATE,
  [Filter.Custom]: (client: Client) => client.category === SessionType.CUSTOM,
  [Filter.OpenWeve]: (client: Client) =>
    client.category === SessionType.OPEN_WEVE
}

export default Vue.extend({
  name: "ClientsPage",
  components: {
    TextHighlight,
    RtbButton,
    RtbLink,
    RtbCard,
    RtbSelect,
    RtbCardBody,
    RtbSpinner,
    SearchField,
    ClientEdit
  },
  data() {
    return {
      clients: [] as Client[],
      client: null as Client | null,
      query: "",
      pagination: { page: 1, rowsPerPage: 20 },
      filter: Filter.Template,
      action: undefined as Action | undefined,
      isModalVisible: false,
      loading: false,
      isAllClientsFetched: false
    }
  },
  watch: {
    filter() {
      this.getClients()
    }
  },
  computed: {
    isShardSession() {
      return this.$store.getters[
        `${AUTH_MODULE_NAME}/${AuthGetterTypes.IS_SHARD_SESSSION}`
      ]
    },
    user(): any {
      return this.$store.getters.user
    },
    orgID(): string {
      return this.$store.state.orgID
    },
    headers(): { text: string; value?: string; sortable: boolean }[] {
      const items = [
        {
          text: "ID",
          value: "id",
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          sortable: false
        },
        {
          text: "Added By",
          value: "user.firstname",
          sortable: false
        },
        {
          text: "Game ID",
          value: "gameID",
          sortable: false
        },
        {
          text: "Login",
          sortable: false
        }
      ]

      if (!this.isShardSession) {
        items.push({
          text: "Action",
          sortable: false
        })
      }

      return items
    },
    filteredClients(): Client[] {
      const { clients } = this
      const reversedClinets = clients.slice().reverse()

      if (this.filter !== undefined) {
        return reversedClinets.filter(FILTER_TO_PREDICATE[this.filter])
      }

      return reversedClinets
    },
    filters(): any {
      return [
        { value: undefined, label: "All" },
        { value: Filter.Expo, label: "Expo" },
        { value: Filter.Template, label: "Template" },
        { value: Filter.ContentToolTemplate, label: "Content Tool Template" },
        { value: Filter.Standard, label: "Standard" },
        { value: Filter.OpenWeve, label: "Open Weve" }
      ]
    }
  },
  async created() {
    this.loading = true

    const snapshot = await db
      .ref("clients")
      .orderByChild("category")
      .equalTo(SessionType.TEMPLATE)
      .limitToFirst(5000)
      .once("value")

    const orgID = this.orgID

    this.clients = Session.normalize(snapshot.val()).filter(
      obj => obj?.orgID === orgID
    )

    this.loading = false
  },
  methods: {
    navigateToMaster() {
      const masterID = String(process.env.VUE_APP_DEFAULT_SESSION_ID).trim()
      window.open(`${window.location.origin}/login/${masterID}?auth=0`, "_self")
    },
    getClients(options = { limit: 1000 }) {
      this.loading = true
      return this.$services
        .get("client")
        .then(service => service.getClients(this.orgID, options))
        .then(clients => {
          this.clients = clients
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAllClients() {
      this.getClients({ limit: 10000 }).then(() => {
        this.isAllClientsFetched = true
      })
    },
    initClientCreation() {
      this.action = "create"
      this.isModalVisible = true
    },
    initClientEdit(client: Client) {
      this.action = "edit"
      this.client = { ...client }
      this.isModalVisible = true
    },
    handleCopyClient(client: Client) {
      this.action = "copy"
      this.client = { ...client }
      this.isModalVisible = true
    },
    async remove(client: Client) {
      if (
        confirm(
          `Are you sure want to delete the client called ${client.name}? NOTE: This does not delete the game`
        )
      ) {
        await this.$services
          .get("client")
          .then(service => service.deleteClient(client.id))
        this.clients = this.clients.filter(c => c.id !== client.id)
      }
    },
    updateClients(client: Client) {
      const index = this.clients.findIndex(c => c.id === client.id)
      if (index !== -1) {
        this.$set(this.clients, index, client)
      } else {
        this.clients.push(client)
      }
    },
    onClientInput(client: Client) {
      this.isModalVisible = false
      this.client = null
      this.action = undefined
      this.updateClients(client)
    }
  }
})
