



























































































































































































































































































































































































































import type { PropType } from "vue"
import Vue from "vue"
import mixins from "vue-typed-mixins"
import invariant from "invariant"
import { cloneDeep } from "lodash"

import type { Client } from "@/types/client"

import {
  CLIENT_GAME_ASSIGNMENT_TYPES,
  CLIENT_CUSTOM_INPUT_TYPES
} from "@/config"

import ClientImagesMixin from "@/mixins/client-images"

import {
  RtbButton,
  RtbLink,
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbTextarea,
  RtbSelect,
  RtbCheckbox,
  RtbTabs,
  RtbTab,
  RtbSpinner,
  RtbInlineHelp,
  RtbDatepicker,
  RtbTimepicker
} from "@/components/ui"

import RunStatus from "@shared/enums/Game"

import { createDefaultOptions } from "@/components/ui/Select/utils"
import ImageUploaderNext from "@/components/ImageUploader.vue"
import ClientFactory from "@/entities/ClientFactory"
import { Firebase } from "@/helpers/firebase"
import type { Game } from "@/types/game"
import Session from "@shared/enums/Session"

import SessionService from "@/services/client"
import { copyClient } from "@/services/api.service"
import { pick } from "lodash"

export const actions = ["create", "edit", "copy"] as const

export type Action = typeof actions[number]

export default mixins(Vue.extend(ClientImagesMixin)).extend({
  name: "ClientEdit",
  props: {
    client: {
      type: Object as PropType<Client>
    },
    action: {
      type: String as PropType<Action>,
      required: true
    }
  },
  components: {
    RtbButton,
    RtbLink,
    RtbRow,
    RtbCol,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbTextarea,
    RtbSelect,
    RtbCheckbox,
    RtbTabs,
    RtbTab,
    RtbSpinner,
    RtbInlineHelp,
    ImageUploaderNext,
    RtbDatepicker,
    RtbTimepicker
  },
  data() {
    return {
      tab: 0,
      localClient: null as null | Client,
      password: undefined as string | undefined,
      clientGames: [] as Game[],
      newClientGames: [] as Game[],
      // TODO(Andrew): move to it's own component to prevent overfetching
      mastersGames: [] as Game[],
      loading: false,
      newTournamentGameID: undefined as string | undefined,
      baseClient: null as null | Client
    }
  },
  computed: {
    user(): unknown {
      return this.$store.getters.user
    },
    orgs(): unknown[] {
      return Firebase.normalizeSnapshotToArray(this.$store.state.org.orgs)
    },
    orgID(): string {
      return this.$store.getters.orgID
    },
    clientID(): string {
      return this.client?.id
    },
    isCreating(): boolean {
      return this.action === "create"
    },
    isEditing(): boolean {
      return this.action === "edit"
    },
    isСopying(): boolean {
      return this.action === "copy"
    },
    cetegoriesOptions() {
      return createDefaultOptions(Object.values(Session))
    },
    canTurnToExpo() {
      return !this.localClient?.tournament
    }
  },
  watch: {
    client: {
      async handler(value: Client) {
        if (this.action === "edit" || this.action === "copy") {
          const password = await this.$services
            .get("client")
            .then(service => service.getPassword(value.id))
          // TODO(Andrew): OOP Prototype Pattern?
          this.localClient = cloneDeep(value)
          this.baseClient = cloneDeep(value)
          this.password = password
        }
      },
      immediate: true
    },
    action: {
      handler(value: Action) {
        if (value === "create") {
          this.localClient = new ClientFactory().create({ orgID: this.orgID })
        }
      },
      immediate: true
    }
  },
  created() {
    this.getMastersGames()
    if (this.client) {
      this.getClientGames()
    }
  },
  methods: {
    getRestrictions() {
      return CLIENT_GAME_ASSIGNMENT_TYPES
    },
    getCustomInputTypes() {
      return CLIENT_CUSTOM_INPUT_TYPES
    },
    // TODO(Andrew): move to it's own component to prevent overfetching
    getMastersGames() {
      this.$services
        .get("game")
        .then(service =>
          service.getGamesByRunStatus(this.orgID, RunStatus.MASTER)
        )
        .then(games => {
          this.mastersGames = games
        })
    },
    getClientGames() {
      this.$services
        .get("game")
        .then(service => service.getGamesByClientID(this.orgID, this.clientID))
        .then(games => {
          this.clientGames = games
        })
    },
    async getNewClientGames(clientID) {
      this.$services
        .get("game")
        .then(service => service.getGamesByClientID(this.orgID, clientID))
        .then(games => {
          this.newClientGames = games
        })
    },
    async create() {
      invariant(this.localClient, "Session is not initialized")
      this.loading = true
      const { localClient } = this
      const { gameID } = localClient
      if (gameID) {
        await this.updateGames()
      }
      const createdClient = await this.$services
        .get("client")
        .then(service => service.addClient(localClient))
      this.$emit("input", createdClient)
      this.loading = false
    },
    async update() {
      this.loading = true

      // TODO(Andrew|someone): update only when it's needed
      await this.updatePassword()

      await this.updateGames()
      const updatedClient = await this.$services
        .get("client")
        .then(service => service.updateClient(this.localClient))

      this.$emit("input", updatedClient)
      this.baseClient = cloneDeep(updatedClient)
      this.loading = false
    },
    async updatePassword() {
      invariant(this.localClient, "Session is not initialized")
      const { localClient } = this
      if (localClient.regularLogin) this.password = null

      const service = await this.$services.get("client")

      let payload

      if (!this.password) {
        payload = { auth: null, password: null }
      } else {
        payload = { auth: true, password: this.password }
      }

      await service.setAuth(localClient.id, payload)
    },
    updateGames() {
      if (this.localClient.gameID !== this.client.gameID) {
        const { orgID } = this.client
        this.$services.get("game").then(service =>
          service.updateGamess(this.orgID, this.localClient.gameID, {
            clientID: this.localClient.id
          })
        )
      }
    },
    async copy() {
      invariant(this.localClient, "Session is not initialized")
      this.loading = true
      const localClient = { ...this.localClient }
      const newClientRes = await copyClient({
        token: this.$store.state.auth.token,
        orgID: this.user.super ? localClient.orgID : this.orgID,
        id: this.localClient?.id,
        ...pick(localClient, [
          "name",
          "description",
          "category",
          "redirectURL",
          "startTimestamp",
          "locked",
          "tipsDisabled",
          "restriction",
          "gameID"
        ]),
        legacy: true
      })

      this.$emit("input", newClientRes?.data?.client)
      this.loading = false
    },
    turnToExpo() {
      SessionService.setLobby(this.localClient, true)
    },
    async addTournamentGame() {
      const gameID = this.newTournamentGameID
      invariant(gameID, "No gameID is given")
      const game = await this.$services
        .get("game")
        .then(service => service.getGameByID(this.orgID, gameID))
      invariant(game, `There is no game with ${gameID} id`)
      // TODO(Andrew): this shouldn't be in component
      game.clientID = this.client.id
      game.theKey = this.newTournamentGameID
      game.sameClientID = true
      game.runStatus = RunStatus.TOURNAMENT
      const newGameID = await this.$store.dispatch("Games/copyGame", { game })
      const newGame = await this.$services
        .get("game")
        .then(service => service.getGameByID(this.orgID, newGameID))
      this.clientGames.push(newGame)
      this.newTournamentGameID = undefined
    },
    async removeGame(game: Game) {
      if (confirm(`Are you sure you want to delete ${game.name}`)) {
        const { clientID, id: gameID } = game

        await this.$services
          .get("game")
          .then(service => service.deleteGame(this.orgID, gameID))

        if (this.client.gameID === gameID) {
          await this.$services
            .get("client")
            .then(service =>
              service.updateClient({ id: this.client.id, gameID: null })
            )
          this.localClient.gameID = null
        }

        this.clientGames = this.clientGames.filter(
          clientGame => clientGame !== game
        )
      }
    }
  }
})
