import type { DefaultOption } from "./types"

export function createDefaultOptions(
  array: string[],
  labels?: Record<string, string>
): DefaultOption<string>[] {
  return array.map(value => ({
    id: value,
    label: labels ? labels[value] : value
  }))
}
