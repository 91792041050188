import { v4 } from "uuid"

import type { Client } from "@/types/client"

import { DEFAULT_LATERCOMER_YOUTUBE_CODE } from "@/config"

export default class ClientFactory {
  create(mix: Partial<Client> & { orgID: string }): Client {
    const identity = v4()
    return {
      id: identity,
      theKey: identity,
      name: `Client | ${new Date()}`,
      latecomerVideoCode: DEFAULT_LATERCOMER_YOUTUBE_CODE,
      ...mix
    }
  }
}
