var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Clients")]),
      _c(
        "rtb-card",
        [
          _c("rtb-card-body", [
            _c(
              "div",
              { staticClass: "rtb-d-flex align-center" },
              [
                !_vm.isShardSession
                  ? _c(
                      "rtb-button",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "success" },
                        on: { click: _vm.initClientCreation },
                      },
                      [
                        _c("svg-icon", { attrs: { name: "plus-regular" } }),
                        _vm._v(" Add new Session "),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("search-field", {
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
                _c("rtb-select", {
                  staticClass: "w-64",
                  attrs: {
                    options: _vm.filters,
                    identity: "value",
                    label: "Filters",
                    placeholder: "Filters",
                    "hide-label": "",
                  },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
                _vm.isShardSession
                  ? [
                      _c(
                        "h3",
                        {
                          staticClass: "pl-2",
                          staticStyle: { color: "yellowgreen" },
                        },
                        [
                          _vm._v(" For more admin capabilities "),
                          _c(
                            "a",
                            {
                              staticClass: "clients-page__master-link",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.navigateToMaster.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("login")]
                          ),
                          _vm._v(
                            " to a client that is hosted on the master database. "
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c(
                  "rtb-button",
                  {
                    staticClass: "ml-auto",
                    attrs: {
                      color: "secondary",
                      disabled: _vm.loading || _vm.isAllClientsFetched,
                    },
                    on: { click: _vm.getAllClients },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { name: "cloud-download-regular" },
                    }),
                    _vm._v(" Get All Clients"),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredClients,
          search: _vm.query,
          pagination: _vm.pagination,
          loading: _vm.loading,
          "item-key": "id",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [
                    _c(
                      "span",
                      { staticClass: "text-white whitespace-no-wrap" },
                      [
                        _c(
                          "text-highlight",
                          { attrs: { queries: _vm.query } },
                          [_vm._v(_vm._s(item.id))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "rtb-link",
                        {
                          attrs: { href: "#", role: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.initClientEdit(item)
                            },
                          },
                        },
                        [
                          _c(
                            "text-highlight",
                            { attrs: { queries: _vm.query } },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("td", [
                    _vm._v(
                      " " + _vm._s(item.user ? item.user.firstname : "") + " "
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      item.gameID
                        ? _c(
                            "rtb-link",
                            {
                              attrs: {
                                to: {
                                  name: "dashboard.games",
                                  query: { id: item.gameID },
                                },
                              },
                            },
                            [
                              _c(
                                "text-highlight",
                                { attrs: { queries: _vm.query } },
                                [_vm._v(_vm._s(item.gameID))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "rtb-link",
                        {
                          attrs: {
                            href: "/gamelogin/" + item.id + "?auth=0",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" Login ")]
                      ),
                      _c("br"),
                      _c(
                        "rtb-link",
                        {
                          attrs: {
                            href:
                              "/gamelogin/" + item.theKey + "?audit=1&auth=0",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Audit")]
                      ),
                    ],
                    1
                  ),
                  !_vm.isShardSession
                    ? _c(
                        "td",
                        { attrs: { width: "135px" } },
                        [
                          _c(
                            "rtb-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "icon", "aria-label": "Edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.initClientEdit(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "pen-regular" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "icon", color: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCopyClient(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "copy-regular" },
                              }),
                            ],
                            1
                          ),
                          _vm.user.super
                            ? _c(
                                "rtb-button",
                                {
                                  attrs: {
                                    color: "danger",
                                    variant: "icon",
                                    "aria-label": "Delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(item)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { name: "trash-regular" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("rtb-spinner")],
                      1
                    )
                  : _c("span", [_vm._v("No Data Available")]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.isShardSession
        ? _c(
            "v-dialog",
            {
              attrs: {
                width: "768px",
                persistent: "",
                "no-click-animation": "",
              },
              model: {
                value: _vm.isModalVisible,
                callback: function ($$v) {
                  _vm.isModalVisible = $$v
                },
                expression: "isModalVisible",
              },
            },
            [
              _vm.client || _vm.action === "create"
                ? _c("ClientEdit", {
                    key: _vm.client ? _vm.client.id : Date.now(),
                    attrs: { client: _vm.client, action: _vm.action },
                    on: { input: _vm.onClientInput },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "actions",
                          fn: function (ref) {
                            var loading = ref.loading
                            return [
                              _c(
                                "rtb-button",
                                {
                                  attrs: { color: "dark", disabled: loading },
                                  on: {
                                    click: function ($event) {
                                      _vm.isModalVisible = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2450643736
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }