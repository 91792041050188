var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "client-edit", staticStyle: { width: "100%" } },
    [
      _vm.localClient
        ? _c(
            "rtb-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "rtb-tab",
                { key: "general", attrs: { title: "General" } },
                [
                  _c(
                    "rtb-card-body",
                    [
                      _c(
                        "rtb-row",
                        [
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-text-input", {
                                attrs: { label: "Name", disabled: _vm.loading },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Name show on the login page"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1251710731
                                ),
                                model: {
                                  value: _vm.localClient.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "name", $$v)
                                  },
                                  expression: "localClient.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  label: "Description",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Generic Description for internal use"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  698187951
                                ),
                                model: {
                                  value: _vm.localClient.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.user.super
                            ? _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.orgs,
                                      label: "Org",
                                      "option-text": "name",
                                      disabled: _vm.loading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("Placeholder"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1389206948
                                    ),
                                    model: {
                                      value: _vm.localClient.orgID,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localClient, "orgID", $$v)
                                      },
                                      expression: "localClient.orgID",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  disabled: _vm.loading,
                                  label: "Game ID",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v("For database use"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3514365658
                                ),
                                model: {
                                  value: _vm.localClient.gameID,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "gameID", $$v)
                                  },
                                  expression: "localClient.gameID",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.cetegoriesOptions,
                                  label: "Category",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "If it is a template, it will show up in Portal"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3296090692
                                ),
                                model: {
                                  value: _vm.localClient.category,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "category", $$v)
                                  },
                                  expression: "localClient.category",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.getRestrictions(),
                                  identity: "code",
                                  label: "Game Assignment",
                                  "option-text": "text",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Method used for player auto assignment to games"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3597456333
                                ),
                                model: {
                                  value: _vm.localClient.restriction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "restriction",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.restriction",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  label: "Redirect URL",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v("Redirect Full URL "),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  273490373
                                ),
                                model: {
                                  value: _vm.localClient.redirectURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "redirectURL",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.redirectURL",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-datepicker", {
                                attrs: {
                                  label: "Session Start Date",
                                  timestamp: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Defines base date and time for copies"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2660564054
                                ),
                                model: {
                                  value: _vm.localClient.startTimestamp,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "startTimestamp",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.startTimestamp",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-timepicker", {
                                attrs: {
                                  label: "Session Start Time",
                                  timestamp: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Defines base date and time for copies"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2660564054
                                ),
                                model: {
                                  value: _vm.localClient.startTimestamp,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "startTimestamp",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.startTimestamp",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: { label: "Locked", disabled: _vm.loading },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "Will prevent participatns from entering the lobby, as well as kick out anyone in the game. "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              28552112
                            ),
                            model: {
                              value: _vm.localClient.locked,
                              callback: function ($$v) {
                                _vm.$set(_vm.localClient, "locked", $$v)
                              },
                              expression: "localClient.locked",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Disable Tips",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "This will prevent the tips animation from showing after the game"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1476841798
                            ),
                            model: {
                              value: _vm.localClient.tipsDisabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.localClient, "tipsDisabled", $$v)
                              },
                              expression: "localClient.tipsDisabled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rtb-tab",
                { key: "login", attrs: { title: "Login" } },
                [
                  _c(
                    "rtb-card-body",
                    [
                      _c(
                        "rtb-row",
                        [
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  label: "Tagline",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Appears on the login page below the logo"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1706485610
                                ),
                                model: {
                                  value: _vm.localClient.tagline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "tagline", $$v)
                                  },
                                  expression: "localClient.tagline",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.localClient.customInputType === "password" ||
                          _vm.password
                            ? _c("rtb-col", { attrs: { xs: "4" } }, [
                                _c(
                                  "form",
                                  { attrs: { autocomplete: "off" } },
                                  [
                                    _c("rtb-text-input", {
                                      attrs: {
                                        label: "Optional Password",
                                        type: "password",
                                        autocomplete: "off",
                                        disabled: _vm.loading,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "help",
                                            fn: function () {
                                              return [
                                                _c("rtb-inline-help", [
                                                  _vm._v(
                                                    " A password to gain access to the game "
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1855510795
                                      ),
                                      model: {
                                        value: _vm.password,
                                        callback: function ($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("rtb-col", { attrs: { xs: "4" } }),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.getCustomInputTypes(),
                                  identity: "value",
                                  label: "Custom Input",
                                  "option-text": "label",
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Another field added to the login page"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1052758300
                                ),
                                model: {
                                  value: _vm.localClient.customInputType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localClient,
                                      "customInputType",
                                      $$v
                                    )
                                  },
                                  expression: "localClient.customInputType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.localClient.customInputType
                            ? _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Custom Input Label",
                                      disabled: _vm.loading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The name of the custom input field"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      214303310
                                    ),
                                    model: {
                                      value: _vm.localClient.customInputLabel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localClient,
                                          "customInputLabel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localClient.customInputLabel",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.localClient.customInputType === "select"
                            ? _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("rtb-textarea", {
                                    attrs: {
                                      label:
                                        "Custom Input Options (comma separated)",
                                      disabled: _vm.loading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The type of field for the custom input."
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3189816804
                                    ),
                                    model: {
                                      value: _vm.localClient.customInputOptions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localClient,
                                          "customInputOptions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localClient.customInputOptions",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Email Login",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "Requires email and password as well as email verification"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              499147718
                            ),
                            model: {
                              value: _vm.localClient.regularLogin,
                              callback: function ($$v) {
                                _vm.$set(_vm.localClient, "regularLogin", $$v)
                              },
                              expression: "localClient.regularLogin",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Hide GoGame",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v("Placeholder"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1389206948
                            ),
                            model: {
                              value: _vm.localClient.hideGoGame,
                              callback: function ($$v) {
                                _vm.$set(_vm.localClient, "hideGoGame", $$v)
                              },
                              expression: "localClient.hideGoGame",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Use Avatar",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "Use a preloaded animal avatar instead of a personal photo"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              516002446
                            ),
                            model: {
                              value: _vm.localClient.noUserPhoto,
                              callback: function ($$v) {
                                _vm.$set(_vm.localClient, "noUserPhoto", $$v)
                              },
                              expression: "localClient.noUserPhoto",
                            },
                          }),
                          _c("rtb-checkbox", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Onboarding Team Select",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "This allows the users to select the game and team they will be playing for on the onboarding"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1647797959
                            ),
                            model: {
                              value: _vm.localClient.showChooseGameCard,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localClient,
                                  "showChooseGameCard",
                                  $$v
                                )
                              },
                              expression: "localClient.showChooseGameCard",
                            },
                          }),
                          _c("rtb-checkbox", {
                            attrs: {
                              label: "Allow Latecomers Auto Assign",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v(
                                          "Enable auto assing for in-progress games"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4199482329
                            ),
                            model: {
                              value: _vm.localClient.allowLatecomersAutoAssign,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localClient,
                                  "allowLatecomersAutoAssign",
                                  $$v
                                )
                              },
                              expression:
                                "localClient.allowLatecomersAutoAssign",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rtb-tab",
                { key: "images", attrs: { title: "Images" } },
                [
                  _c(
                    "rtb-card-body",
                    [
                      _c(
                        "rtb-row",
                        [
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("image-uploader-next", {
                                attrs: {
                                  label: "Top Left Image in Lobby",
                                  disabled: _vm.loading,
                                },
                                model: {
                                  value: _vm.localClient.logoImage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "logoImage", $$v)
                                  },
                                  expression: "localClient.logoImage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-col",
                            { attrs: { xs: "4" } },
                            [
                              _c("image-uploader-next", {
                                attrs: {
                                  label: "Login Page Top Logo",
                                  disabled: _vm.loading,
                                },
                                model: {
                                  value: _vm.localClient.loginLogo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localClient, "loginLogo", $$v)
                                  },
                                  expression: "localClient.loginLogo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("rtb-col", { attrs: { xs: "4" } }),
                          _vm._l(_vm.clientImages, function (image, index) {
                            return _c(
                              "rtb-col",
                              { key: index, attrs: { xs: "3" } },
                              [
                                _c("image-uploader-next", {
                                  attrs: {
                                    label: "Image " + (index + 1),
                                    disabled: _vm.loading,
                                  },
                                  model: {
                                    value: _vm.clientImages[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.clientImages, index, $$v)
                                    },
                                    expression: "clientImages[index]",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.canTurnToExpo
                ? _c(
                    "rtb-tab",
                    { attrs: { title: "Actions" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _vm.canTurnToExpo
                            ? _c(
                                "rtb-button",
                                {
                                  attrs: { disabled: _vm.loading },
                                  on: { click: _vm.turnToExpo },
                                },
                                [_vm._v("Turn To Expo")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.localClient.tournament
                ? _c(
                    "rtb-tab",
                    { key: "games", attrs: { title: "Games" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.mastersGames,
                                      label: "Copy Game",
                                      "option-text": "name",
                                      disabled: _vm.loading,
                                    },
                                    model: {
                                      value: _vm.newTournamentGameID,
                                      callback: function ($$v) {
                                        _vm.newTournamentGameID = $$v
                                      },
                                      expression: "newTournamentGameID",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.newTournamentGameID
                                ? _c(
                                    "rtb-col",
                                    {
                                      staticClass: "self-end",
                                      attrs: { xs: "4" },
                                    },
                                    [
                                      _c(
                                        "rtb-button",
                                        {
                                          attrs: {
                                            color: "success",
                                            disabled: _vm.loading,
                                          },
                                          on: { click: _vm.addTournamentGame },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: { name: "plus-regular" },
                                          }),
                                          _vm._v(" Add"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.clientGames.length > 0
                            ? _c(
                                "div",
                                { staticClass: "mt-3 client-edit__scrollable" },
                                _vm._l(_vm.clientGames, function (game) {
                                  return _c(
                                    "rtb-row",
                                    { key: game.id },
                                    [
                                      _c(
                                        "rtb-col",
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: { xs: "4" },
                                        },
                                        [
                                          _c(
                                            "rtb-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "dashboard.games",
                                                  query: { id: game.id },
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(game.id))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-col",
                                        {
                                          staticClass:
                                            "shrink d-flex align-center",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(game.name) +
                                              " " +
                                              _vm._s(game.originalGameID) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "rtb-col",
                                        { staticClass: "grow text-xs-right" },
                                        [
                                          _c(
                                            "rtb-button",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                color: "danger",
                                                variant: "icon",
                                                disabled: _vm.loading,
                                                "aria-label": "Delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeGame(game)
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "trash-regular",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "rtb-card-actions",
        [
          _c("rtb-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "mr-2",
          }),
          _vm._t("actions", null, { loading: _vm.loading }),
          _vm.isCreating
            ? _c(
                "rtb-button",
                {
                  attrs: { color: "success", disabled: _vm.loading },
                  on: { click: _vm.create },
                },
                [_vm._v(" Create ")]
              )
            : _vm._e(),
          _vm.isСopying
            ? _c(
                "rtb-button",
                {
                  attrs: { color: "secondary", disabled: _vm.loading },
                  on: { click: _vm.copy },
                },
                [
                  _c("svg-icon", { attrs: { name: "copy-regular" } }),
                  _vm._v(" Copy "),
                ],
                1
              )
            : _vm._e(),
          _vm.isEditing
            ? _c(
                "rtb-button",
                { attrs: { disabled: _vm.loading }, on: { click: _vm.update } },
                [
                  _c("svg-icon", { attrs: { name: "save-regular" } }),
                  _vm._v(" Save "),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }